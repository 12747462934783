import React from "react";
import { ReactComponent as TwigSvg } from "./Twig.svg";

const Twig = () => {
  const editedIcon = React.cloneElement(<TwigSvg />, {
    style: { maxHeight: "32px", width: "200px", fill: "white" },
  });
  return <>{editedIcon}</>;
};

export const TwigMobile = () => {
  const editedIcon = React.cloneElement(<TwigSvg />, {
    style: { maxHeight: "26px", width: "140px", fill: "white" },
  });
  return <>{editedIcon}</>;
};

export default Twig;
