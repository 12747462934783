import React from "react";
import { ReactComponent as TiktokSvg } from "./Tiktok.svg";
import { ReactComponent as YoutubeSvg } from "./Youtube.svg";
import { ReactComponent as InstaSvg } from "./Instagram.svg";

const size = "55px";

const Tiktok = () => {
  const editedIcon = React.cloneElement(<TiktokSvg />, {
    style: { maxHeight: size, width: size },
  });
  return <>{editedIcon}</>;
};

const Youtube = () => {
  const editedIcon = React.cloneElement(<YoutubeSvg />, {
    style: { maxHeight: size, width: size },
  });
  return <>{editedIcon}</>;
};

const Instagram = () => {
  const editedIcon = React.cloneElement(<InstaSvg />, {
    style: { maxHeight: size, width: size },
  });
  return <>{editedIcon}</>;
};

const List = [
  { icon: <Tiktok />, link: "https://www.tiktok.com/@adiacharvat" },
  {
    icon: <Youtube />,
    link: "https://www.youtube.com/channel/UCGQJqLB5I2ZYVP2vwQOSWHg/featured",
  },
  { icon: <Instagram />, link: "https://www.instagram.com/adiacharvat/" },
];
function getListSiti() {
  return List;
}

export default getListSiti;
