import { Box, Typography } from "@mui/material";
import React from "react";
import { greenLight } from "../../Colors";
import getVlnky from "../../Images/Vlnky/Vlnky";

export default function YoutubePanel() {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            backgroundColor: greenLight,
            width: "100%",
            py: "80px",
          }}
        >
          <Box></Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: { xs: "40px", sm: "50px" },
              pb: { xs: "10px", sm: "20px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: { xs: "100%", sm: "100%", md: "889px" },
                height: { xs: "280px", sm: "420px", md: "500px" },
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/xijw2UepQ_M"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "1200px",
              width: "100%",
              display: "flex",
            }}
          >
            <Box sx={{ display: "flex", width: "100%", mt: "-3px" }}>
              {getVlnky(2)}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
