import { Typography } from "@mui/material";
import { greenDark } from "../../Colors";
const nadpisStyle = {
  fontSize: "30px",
  lineHeight: "34px",
  height: "85px",
  fontFamily: "EB Garamond",
  color: greenDark,
  letterSpacing: "0.00938em",
};
const textStyle = {
  fontSize: "15px",
  lineHeight: "22px",
  fontFamily: "RawlineRegular",
  color: greenDark,
};
export const nadpisy = [
  <Typography variant="h2" sx={nadpisStyle}>
    Živou hudbu pro váš svatební den
  </Typography>,
  <Typography variant="h2" sx={nadpisStyle}>
    Hudební
    <br />
    večery
  </Typography>,
  <Typography variant="h2" sx={nadpisStyle}>
    Vystoupení na společenských akcích
  </Typography>,
];

export function getTexty(sNabidkaRefScroll = null) {
  return [
    <Typography sx={textStyle}>
      Ráda vám zpříjemním svatební den živou hudbou. Zazpívám vám ke svatebnímu
      obřadu i hostům ve svatebním odpoledni. Více informací naleznete v mé{" "}
      <a
        onClick={() => {
          sNabidkaRefScroll();
        }}
        style={{ ...textStyle, fontFamily: "RawlineBold", cursor: "pointer" }}
      >
        svatební nabídce
      </a>
      .
    </Typography>,
    <Typography sx={textStyle}>
      Zajistím hudební večer pro hosty ve vašem podniku. V mém repertoáru
      naleznete české i zahraniční písničky pro pohodovou atmosféru a
      nezapomenutelný zážitek.
    </Typography>,
    <Typography sx={textStyle}>
      Ať už chystáte oslavu narozenin, křtiny, výročí, nebo firemní akci, ráda
      vám zajistím živou hudbu pro výjimečnou atmosféru.
    </Typography>,
  ];
}

export const props = [{ sm: 24 }, { sm: 12 }, { sm: 12 }];
