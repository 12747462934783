import React from "react";
import { ReactComponent as List1Svg } from "./Listy1.svg";
import { ReactComponent as List2Svg } from "./Listy2.svg";
import { ReactComponent as List3Svg } from "./Listy3.svg";

const List1 = () => {
  const editedIcon = React.cloneElement(<List1Svg />, {
    style: { maxHeight: "44px", width: "200px" },
  });
  return <>{editedIcon}</>;
};

const List2 = () => {
  const editedIcon = React.cloneElement(<List2Svg />, {
    style: { maxHeight: "34px", width: "200px" },
  });
  return <>{editedIcon}</>;
};

const List3 = () => {
  const editedIcon = React.cloneElement(<List3Svg />, {
    style: { maxHeight: "40px", width: "200px" },
  });
  return <>{editedIcon}</>;
};

const Listy = [<List1 />, <List2 />, <List3 />];
function getList(index) {
  return Listy[index];
}

export default getList;
