import React from "react";
import { ReactComponent as Button1Svg } from "./Button1.svg";
import { ReactComponent as Button2Svg } from "./Button2.svg";
import { ReactComponent as Button3Svg } from "./Button3.svg";

const size = "100%";

const Button1 = () => {
  const editedIcon = React.cloneElement(<Button1Svg />, {
    style: { maxHeight: size, width: size },
  });
  return <>{editedIcon}</>;
};

const Button2 = () => {
  const editedIcon = React.cloneElement(<Button2Svg />, {
    style: { maxHeight: size, width: size },
  });
  return <>{editedIcon}</>;
};

const Button3 = () => {
  const editedIcon = React.cloneElement(<Button3Svg />, {
    style: { maxHeight: size, width: size },
  });
  return <>{editedIcon}</>;
};

export const Buttony = [<Button1 />, <Button2 />, <Button3 />];
function getButton(index) {
  return Buttony[index];
}

export default getButton;
