export const reference = [
  {
    text: "Dobrý den :) Ještě jednou jsme vám chtěli mockrát poděkovat, že jste přijeli na naši svatbu a byli jste tak neodmyslitelnou součástí našeho velkého dne ❤️ Zpívala jsem naprosto úžasně a troufám si říct, že jste tak měla velký podíl na vytvoření úžasné a pohodové atmosféry, která na svatbě panovala ❤️ Ještě jednou děkujeme, máte božský hlas a určitě bych vás všem doporučila ❤️ Krásný den přeji a ať se daří vám i vašemu partnerovi, kterého též zdravíme :) S pozdravem - Tomanová 😂",
    name: "Lucie Tomanová",
    insta: null,
    web: null,
  },
  {
    text: "Ahoj Adélko a Jardo, my se právě vracíme ze svatební cesty ze Slovinska. Tak nějak ještě pořád vstřebáváme, jak krásný den ta minulá sobota pro nás byla a z velké části i díky Vám dvěma. My jsme věděli už po schůzce s vámi v Podnebí, že to bude mega fajn, ale to jakou jste tam navodili atmosféru bylo jak z pohádky. Naši nejbližší byli unešeni jak překrásně zpíváš ❤️Moc Vám oběma z celého srdce děkujeme a těšíme se, že se ještě někdy uvidíme. Na tvůj koncert kdekoliv, strašně rádi zajdeme! Posílám pár foteček od fotografa 😊",
    name: "Jana",
    insta: null,
    web: null,
  },
  {
    text: "S Adélkou jsme se poprvé potkali na Čokoládovém festivalu. Má úžasný hlas. Na tom jsme se shodli úplně všichni. Proto jsme ji rádi pozvali i na další zastávky Čokofestu a těšíme se na další její vystoupení. Pokud máte rádi příjemný hlas, pohodu a rádi podporujete mladé umělce, Adélka je nejlepší volba. Vlasťa Macík (moderátor)",
    name: "Vlasta Macík",
    insta: null,
    web: null,
  },
];
