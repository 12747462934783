import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import getVlnky from "../../Images/Vlnky/Vlnky";
import F2 from "../../Images/Photos/F2.webp";
import { greenDark, greenLight, greenNormal } from "../../Colors";
import getListSiti from "../../Images/Icons/SocialniSite/SocSite";
import { motion } from "framer-motion";

const textStyle = {
  fontFamily: "RawlineRegular",
  color: "white",
  fontSize: "15px",
  lineHeight: "22px",
  mt: "15px",
};

export default function Omne({ omneRef = null, kontaktRefScroll = null }) {
  return (
    <Box sx={{ mb: "0px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "1200px",
            width: "100%",
            display: "flex",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", mb: "-3px" }}>
            {getVlnky(0)}
          </Box>
        </Box>
      </Box>

      <Box ref={omneRef} sx={{ backgroundColor: greenNormal }}>
        <Grid
          container
          spacing={2}
          columns={24}
          sx={{ pt: "50px", px: 0, pb: "80px" }}
        >
          <Grid
            item
            xs={24}
            sm={24}
            md={14}
            lg={14}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box sx={{ mx: { xs: "20px", sm: "80px", md: "20px" } }}>
              <Box
                component="img"
                alt="Adia hrající na kytaru"
                src={F2}
                sx={{
                  width: { xs: "100%", sm: "100%", md: "93%" },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={24} sm={24} md={10} lg={10} sx={{}}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mr: "40px",
                ml: { xs: "30px", sm: "60px", md: "20px" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "36px", sm: "45px" },
                  fontFamily: "EB Garamond",
                  color: "white",
                  mb: "13px",
                  letterSpacing: "0.00938em",
                  lineHeight: "1.5",
                }}
                variant="h1"
              >
                O mně
              </Typography>
              <Typography sx={textStyle}>
                Jmenuji se Adéla Charvátová a již od malička se věnuji tvorbě
                hudby. Hudba byla vždy mou vášní a dělá mi radost, když se o ni
                můžu dělit s druhými lidmi.
              </Typography>
              <Typography sx={textStyle}>
                Ve 14 letech jsem začala psát vlastní písně a na střední škole
                už jsem se svou hudbou veřejně vystupovala. Od té doby jsem
                zpívala na mnoha svatbách, akcích a festivalech, při kterých se
                doprovázím na kytaru, klavír nebo ukulele.
              </Typography>
              <Typography sx={textStyle}>
                Ráda poznávám nové lidi a jejich životní příběhy. Přemýšlím
                často až moc do hloubky, a kromě hudby se věnuji studiu
                psychologie na Univerzitě Palackého v Olomouci.
              </Typography>
              <Typography sx={textStyle}>
                Věřím, že živá hudba dokáže vytvořit nenahraditelnou atmosféru,
                chytit lidi za srdce a každé akci dodat jedinečnost a kouzlo.
              </Typography>
              <Typography sx={textStyle}>
                Kontaktovat mě můžete po{" "}
                <a
                  href="tel:+420797996873"
                  style={{
                    ...textStyle,
                    fontFamily: "RawlineBold",
                    textDecoration: "none",
                  }}
                >
                  telefonu
                </a>{" "}
                nebo prostřednictvím níže uvedeného{" "}
                <a
                  onClick={() => {
                    kontaktRefScroll();
                  }}
                  style={{
                    ...textStyle,
                    fontFamily: "RawlineBold",
                    cursor: "pointer",
                  }}
                >
                  formuláře
                </a>
                .
              </Typography>
              <Typography sx={textStyle}>Těším se na vás.</Typography>
              <Box
                sx={{
                  mt: "40px",
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "EB Garamond",
                    fontSize: "24px",
                    ml: { xs: "0px", sm: "-50px", md: "0px" },
                  }}
                >
                  Naleznete mě i zde:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    ml: { xs: "0px", sm: "-50px", md: "20px" },
                    mt: { xs: "15px", sm: "15px", md: "0px" },
                  }}
                >
                  {getListSiti().map((sit, index) => (
                    <Box
                      component={motion.div}
                      whileHover={{ scale: 1.15 }}
                      transition={{
                        type: "spring",
                        stiffness: 600,
                        damping: 70,
                      }}
                      key={"MotionSitList" + index}
                    >
                      <Box
                        component="a"
                        href={sit.link}
                        target="_blank"
                        key={"SitList" + index}
                        sx={{ ml: "10px" }}
                      >
                        {sit.icon}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: greenLight,
        }}
      >
        <Box
          sx={{
            maxWidth: "1200px",
            width: "100%",
            display: "flex",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", mt: "-3px" }}>
            {getVlnky(1)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
