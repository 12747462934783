import { collection, getDocs } from "firebase/firestore";
import { db } from "./config";

export async function getReferences() {
  let references = [];
  const querySnapshot = await getDocs(collection(db, "References"));

  querySnapshot.forEach((doc) => {
    references.push(doc.data());
  });
  return references;
}
