// REFERENCES
const referenceKey = "References";

export function setSessionRef(value, window) {
  window.sessionStorage.setItem(referenceKey, value);
}

export function getSessionRef(window) {
  window.sessionStorage.getItem(referenceKey);
}
