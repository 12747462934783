import React from "react";
import { Box, Typography } from "@mui/material";
import PhoneEnabledRoundedIcon from "@mui/icons-material/PhoneEnabledRounded";

const textStyle = {
  color: "black",
  fontFamily: "EB Garamond",
  fontSize: "16px",
};

const spacing = { pl: { xs: "10px", sm: "40px" } };

export default function Navbar({
  zajistujiRef = null,
  omneRef = null,
  kontaktRef = null,
}) {
  return (
    <header>
      <Box
        sx={{
          width: "100%",
          height: "40px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box sx={{ mr: "auto", display: "flex" }}>
          <Box sx={spacing}>
            <Box
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                if (zajistujiRef !== null) {
                  zajistujiRef();
                }
              }}
            >
              <Typography sx={textStyle}>Nabídka</Typography>
            </Box>
          </Box>
          <Box sx={spacing}>
            <Box
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                if (omneRef !== null) {
                  omneRef();
                }
              }}
            >
              <Typography sx={textStyle}>O mně</Typography>
            </Box>
          </Box>
          <Box sx={spacing}>
            <Box
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                if (kontaktRef !== null) {
                  kontaktRef();
                }
              }}
            >
              <Typography sx={textStyle}>Kontakt</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          component="a"
          href="tel:+420797996873"
          sx={{
            ml: "auto",
            pr: "10px",
            display: { xs: "flex", sm: "none" },
            height: "100%",
            alignItems: "center",
            color: "black",
          }}
        >
          <PhoneEnabledRoundedIcon
            sx={{ width: "20px", height: "20px", fill: "black" }}
          />
        </Box>
        <Box
          sx={{
            ml: "auto",
            pr: "40px",
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Box
            component="a"
            href="tel:+420797996873"
            sx={{ ...textStyle, fontSize: "17px", textDecoration: "none" }}
          >
            797 996 873
          </Box>
        </Box>
      </Box>
    </header>
  );
}
