import { Box, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { greenDark } from "../../Colors";
import getList from "../../Images/Icons/Listy";
import { getTexty, nadpisy, props, texty } from "../Texts/ZajistujiText";

const clanekStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "240px",
};

export default function Zajistuji({
  zajistujiRef = null,
  kontaktRefScroll = null,
  sNabidkaRefScroll = null,
}) {
  return (
    <Box sx={{ mt: { xs: "40px", sm: "60px" } }}>
      <Box
        ref={zajistujiRef}
        sx={{
          width: "100%",
          height: "50px",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "36px", sm: "45px" },
            fontFamily: "EB Garamond",
            color: greenDark,
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
          }}
          variant="h1"
        >
          Co ráda zajistím?
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        columns={24}
        sx={{ mt: { xs: "0px", sm: "40px" } }}
      >
        {nadpisy.map((nadpis, index) => (
          <Grid
            item
            xs={24}
            sm={props[index].sm}
            md={8}
            lg={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
            key={"ZajistujiGrid" + index}
          >
            <Box sx={clanekStyle}>
              <Box
                sx={{
                  mb: "18px",
                  height: "45px",
                  mt: "60px",
                }}
              >
                {getList(index)}
              </Box>
              <Box sx={{ mb: "30px" }}>{nadpis}</Box>
              <Box>{getTexty(sNabidkaRefScroll)[index]}</Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          width: "100%",
          height: "50px",
          mt: { xs: "80px", sm: "150px" },
          mb: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component={motion.div}
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 600, damping: 100 }}
          sx={{ "&:hover": { cursor: "pointer" } }}
          onClick={() => {
            kontaktRefScroll();
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontFamily: "EB Garamond",
              color: greenDark,
              fontWeight: "bold",
            }}
          >
            Zarezervovat termín
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
