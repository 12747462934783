import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { greenDark, greenLight, greenNormal } from "../../../Colors";

export default function KontaktDialog({ open, setOpen, text }) {
  return (
    <Dialog
      open={open !== false}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiPaper-root": { backgroundColor: greenNormal } }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ color: "white" }}>
        {open}
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          sx={{ textTransform: "none", color: "white" }}
        >
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
}
