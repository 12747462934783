import React from "react";
import { ReactComponent as Ruze1Svg } from "./Ruze1.svg";
import { ReactComponent as Ruze2Svg } from "./Ruze2.svg";
import { ReactComponent as Ruze3Svg } from "./Ruze3.svg";

const Ruze1 = () => {
  const editedIcon = React.cloneElement(<Ruze1Svg />, {
    style: { maxHeight: "53px", width: "53px" },
  });
  return <>{editedIcon}</>;
};

const Ruze2 = () => {
  const editedIcon = React.cloneElement(<Ruze2Svg />, {
    style: { maxHeight: "53px", width: "53px" },
  });
  return <>{editedIcon}</>;
};

const Ruze3 = () => {
  const editedIcon = React.cloneElement(<Ruze3Svg />, {
    style: { maxHeight: "53px", width: "53px" },
  });
  return <>{editedIcon}</>;
};

const Ruze = [<Ruze1 />, <Ruze2 />, <Ruze3 />];
function getRuze(index) {
  return Ruze[index];
}

export default getRuze;
