import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import F1 from "../../Images/Photos/F1.webp";
import F1Mobile from "../../Images/Photos/F1Mobile.webp";
import Twig, { TwigMobile } from "../../Images/Icons/Twig";

export default function IntroPanel() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box sx={{ position: "relative", textAlign: "center" }}>
        <img
          src={mobile ? F1Mobile : F1}
          alt="Adia hrající na kytaru"
          style={{ width: "100%" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: mobile ? "20%" : "46%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Twig />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <TwigMobile />
          </Box>

          <Typography
            sx={{
              color: "white",
              fontFamily: "EB Garamond",
              fontSize: { xs: "36px", sm: "50px", md: "58px" },
              lineHeight: { xs: "30px", sm: "47px", md: "55px" },
              letterSpacing: "3px",
            }}
            variant="h1"
          >
            Adéla Charvátová
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontFamily: "RawlineMedium",
              fontSize: { xs: "15px", sm: "17px" },
              mt: "6px",
            }}
            variant="h2"
          >
            Svatební zpěvačka & písničkářka
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
