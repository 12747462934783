import React from "react";
import { ReactComponent as InstagramSvg } from "./Instagram.svg";
import { ReactComponent as WebSvg } from "./Web.svg";

const size = "40px";

const Instagram = () => {
  const editedIcon = React.cloneElement(<InstagramSvg />, {
    style: { maxHeight: size, width: size },
  });
  return <>{editedIcon}</>;
};

const Web = () => {
  const editedIcon = React.cloneElement(<WebSvg />, {
    style: { maxHeight: size, width: size },
  });
  return <>{editedIcon}</>;
};

function getInstagramLink(name) {
  if (
    name.startsWith("https://www.instagram.com/") ||
    name.startsWith("http://www.instagram.com/") ||
    name.startsWith("www.instagram.com/")
  ) {
    return name;
  } else {
    return `https://www.instagram.com/${name}`;
  }
}

export function getReferenceIcons(reference) {
  let icons = [];
  if (reference.insta) {
    icons.push({
      icon: <Instagram />,
      link: getInstagramLink(reference.insta),
    });
  } else if (reference.instagram) {
    icons.push({
      icon: <Instagram />,
      link: getInstagramLink(reference.instagram),
    });
  }

  if (reference.web) {
    icons.push({ icon: <Web />, link: reference.web });
  }
  return icons;
}
