import { Box, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { greenDark } from "../../Colors";
import getButton, { Buttony } from "../../Images/Icons/Button/Button";
import { getReferenceIcons } from "../../Images/Icons/SocialniSite/References/References";
import { reference } from "../Texts/ReferenceBaseText";

const clanekStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "top",
  alignItems: "center",
  maxWidth: "240px",
};

export default function Reference({ references = null }) {
  const [refCount, setRefCount] = useState(3);

  return (
    <Box sx={{ mt: "80px" }}>
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: { xs: "36px", md: "45px" },
            fontFamily: "EB Garamond",
            color: greenDark,
            mb: "20px",
          }}
        >
          Reference
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        columns={24}
        sx={{ mt: { xs: "50px", md: "80px" } }}
      >
        {references.map(
          (refer, index) =>
            index < refCount && (
              <Grid
                item
                xs={24}
                sm={12}
                md={8}
                lg={8}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                key={"ReferenceGrid" + index}
              >
                <Box sx={clanekStyle}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "RawlineRegular",
                      fontSize: "15px",
                      mt: { xs: "30px", md: "0px" },
                    }}
                  >
                    {refer.text}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "left",
                      width: "100%",
                      mt: "20px",
                      fontFamily: "RawlineBold",
                      fontSize: "15px",
                    }}
                  >
                    {refer.name}
                  </Typography>
                  <Box
                    sx={{
                      mt: "5px",
                      mb: "10px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                    }}
                  >
                    {getReferenceIcons(refer).map((data, index) => (
                      <Box
                        component={motion.div}
                        whileHover={{ scale: 1.18 }}
                        transition={{
                          type: "spring",
                          stiffness: 600,
                          damping: 70,
                        }}
                        key={"MotionIconRef" + index}
                        sx={
                          index === 0
                            ? {
                                mt: "10px",
                              }
                            : { mt: "10px", ml: "8px" }
                        }
                      >
                        <Box component="a" href={data.link} target="_blank">
                          {data.icon}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
            )
        )}
        {refCount < references.length && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mt: "80px",
            }}
          >
            <Box
              component={motion.div}
              whileHover={{ scale: 1.15 }}
              transition={{ type: "spring", stiffness: 600, damping: 100 }}
              sx={{
                "&:hover": { cursor: "pointer" },
                width: "fit-content",
                textAlign: "center",
              }}
              onClick={() => {
                setRefCount(references.length);
              }}
            >
              <Typography
                sx={{
                  width: "fit-content",
                  fontSize: { xs: "18px", sm: "24px" },
                  fontFamily: "EB Garamond",
                  color: "black",
                  mb: "10px",
                  userSelect: "none",
                }}
              >
                Zobrazit více
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
    </Box>
  );
}
