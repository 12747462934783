import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { greenDark } from "../../Colors";
import getButton, { Buttony } from "../../Images/Icons/Button/Button";
import getRuze from "../../Images/Ruze/Ruze";
import getVlnky from "../../Images/Vlnky/Vlnky";
import {
  buttonInside,
  buttonLinks,
  nadpisStyle,
  nadpisy,
  props,
  texty,
} from "../Texts/SvatebniNabidkaText";
import { motion } from "framer-motion";

const clanekStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "top",
  alignItems: "center",
  maxWidth: "240px",
};

export default function SvatebniNabidka({ sNabidkaRef = null }) {
  return (
    <Box sx={{ mt: "40px" }}>
      <Box
        ref={sNabidkaRef}
        sx={{ width: "100%", height: "50px", textAlign: "center" }}
      >
        <Typography
          sx={{
            fontSize: { xs: "36px", md: "45px" },
            fontFamily: "EB Garamond",
            color: greenDark,
            mb: "20px",
            letterSpacing: "0.00938em",
            lineHeight: "1.5",
          }}
          variant="h1"
        >
          Svatební nabídka
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        columns={24}
        sx={{ mt: { xs: "0px", sm: "80px" } }}
      >
        {texty.map((text, index) => (
          <Grid
            item
            xs={24}
            sm={index == 0 ? 24 : 12}
            md={8}
            lg={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
            key={"ZajistujiGrid" + index}
          >
            <Box sx={clanekStyle}>
              <Box
                sx={{
                  width: "100px",
                  height: "75px",
                  mt: { xs: "80px", sm: "0px" },
                }}
              >
                {getRuze(index)}
              </Box>
              <Box sx={{ mb: "35px" }}>{nadpisy[index]}</Box>
              <Box
                sx={{
                  display: "flex",
                  textAlign: "left",
                  height: { xs: "auto", sm: "250px" },
                }}
              >
                {text}
              </Box>
              <Box component="a" href={buttonLinks[index]} target="_blank">
                <Box
                  component={motion.div}
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 600, damping: 70 }}
                  sx={{
                    width: "160px",
                    height: "160px",
                    position: "relative",
                    mt: { xs: "40px", sm: "0px" },
                  }}
                >
                  {getButton(index)}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textDecoration: "none",
                    }}
                  >
                    {buttonInside[index]}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
