import { styled, TextField } from "@mui/material";
import { greenDark, greenNormal } from "../../../Colors";

export const StyledTextField = (props) => {
  return (
    <WhiteTextField
      {...props}
      id="outlined-basic"
      variant="outlined"
      color="secondary"
      sx={{ width: "100%", mr: "10px" }}
      InputLabelProps={{
        sx: {
          color: "white",
        },
      }}
    />
  );
};

const WhiteTextField = styled(TextField)({
  /*"& .MuiInput-underline:not(.Mui-disabled):hover::before": {
    borderColor: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white",
  },
  "& .MuiInputBase-input": {
    color: "white",
    //backgroundColor: greenNormal,
    "&:-webkit-autofill": {
      "-webkit-box-shadow": `0 0 0 100px ${greenNormal} inset`,
      "-webkit-text-fill-color": "#fff",
    },
  },*/
});
