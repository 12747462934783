import { Typography } from "@mui/material";
import { greenDark } from "../../Colors";

export const nadpisStyle = {
  fontSize: "30px",
  lineHeight: "30px",
  height: "85px",
  fontFamily: "EB Garamond",
  color: greenDark,
  letterSpacing: "0.00938em",
};
const textStyle = {
  fontSize: "15px",
  lineHeight: "22px",
  fontFamily: "RawlineRegular",
  color: greenDark,
};
const buttonInsideText = {
  color: "white",
  maxWidth: "100px",
  fontFamily: "EB Garamond",
  lineHeight: "20px",
  fontSize: "20px",
  fontWeight: "500",
  "-webkit-touch-callout": "none",
  "-webkit-user-select": "none",
  "-khtml-user-select": "none",
  "-moz-user-select": "none",
  "-ms-user-select": "none",
  "user-select": "none",
};

// SVATEBNI NABIDKA
export const texty = [
  <Typography sx={textStyle}>
    Na svatbách zajišťuji živou hudbu v několika svatebních balíčcích.
    <br />
    <br /> Svatební balíčky obsahují variantu s živou hudbou na obřad, živou
    hudbou na svatební odpoledne i reprodukovanou hudbou na večer a afterpárty.
  </Typography>,
  <Typography sx={textStyle}>
    V mém svatebním repertoáru najdete známé české i zahraniční písničky, mezi
    kterými si jistě každý najde to svoje.
    <br />
    <br />
    Můžete se těšit například na nestárnoucí hity od ABBY, klasiky od Lucie,
    ploužáky Eda Sheerana nebo známé pecky od Beatles.
  </Typography>,
  <Typography sx={textStyle}>
    Termín doporučuji zarezervovat dostatečně dopředu, svatební sezóna se mi
    obvykle rychle zaplní.
    <br />
    <br />
    Volné termíny naleznete v Google kalendáři po rozkliknutí níže přiloženého
    tlačítka.
  </Typography>,
];
export const nadpisy = [
  <Typography variant="h2" sx={nadpisStyle}>
    Svatební
    <br />
    balíčky
  </Typography>,
  <Typography variant="h2" sx={nadpisStyle}>
    Svatební
    <br />
    repertoár
  </Typography>,
  <Typography variant="h2" sx={nadpisStyle}>
    Volné
    <br />
    termíny
  </Typography>,
];

export const buttonInside = [
  <Typography sx={{ ...buttonInsideText, ml: "-6px" }}>
    Svatební balíčky
  </Typography>,
  <Typography sx={{ ...buttonInsideText }}>Svatební repertoár</Typography>,
  <Typography sx={{ ...buttonInsideText, mr: "-8px" }}>
    Volné termíny
  </Typography>,
];

export const buttonLinks = [
  "https://docs.google.com/document/d/1y1Ego5SITXGOI4zc6Yf9FjZH54VOrZ_Vzt1UKAkGcDw/edit?usp=sharing",
  "https://docs.google.com/document/d/1dUFUuCFTT4HmSxVwWY6g9RV378jNubohT3OMBPkffXc/edit?usp=sharing",
  "https://calendar.google.com/calendar/u/4?cid=YWRpYWNoYXJ2YXRAZ21haWwuY29t",
];

export const props = [{ xs: "flex" }, { xs: "flex" }, { xs: "none" }];
