import logo from "./logo.svg";
import "./App.css";
import Page from "./Content/Page";
import { createTheme, ThemeProvider } from "@mui/material";
import themeFile from "./Content/themes";

const theme = createTheme(themeFile);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Page />
    </ThemeProvider>
  );
}

export default App;
