import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { greenDark, greenNormal } from "../../Colors";
import Twig, { TwigMobile } from "../../Images/Icons/Twig";
import getVlnky from "../../Images/Vlnky/Vlnky";
import { StyledTextField } from "./KontaktniFormular/WhiteTextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import { sendContact } from "../../Firebase/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import KontaktDialog from "./KontaktniFormular/KontaktDialog";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, "Překročen počet znaků")
    .min(4, "Nesplňuje minimální délku")
    .required("Zadejte jméno"),
  phone: Yup.string()
    .max(13, "Překročen počet znaků")
    .min(9, "Nesplňuje minimální délku")
    .required("Zadejte telefon"),
  email: Yup.string()
    .email("Email má špatný formát")
    .max(128, "Překročen počet znaků")
    .min(5, "Nesplňuje minimální délku")
    .required("Zadejte email"),
  message: Yup.string()
    .max(300, "Překročen počet znaků")
    .min(5, "Nesplňuje minimální délku")
    .required("Zadejte zprávu"),
});

const initialValues = { name: "", phone: "", email: "", message: "" };

export default function KontaktniFormular({ kontaktRef = null }) {
  const [submited, setSubmited] = React.useState(false);
  const [mainError, setMainError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);

  async function handleFormSubmit(values, { resetForm }) {
    setLoading(true);
    sendContact(values)
      .then((result) => {
        if (result.data.success) {
          resetForm();
          setDialog("Zpráva úspěšně odeslána.");
        } else {
          setDialog(
            "Omlouváme se, při odesílání se vyskytla chyba. Doporučujeme kontaktovat nás telefonicky, případně na email adiacharvat@email.cz"
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setDialog(
          "Omlouváme se, při odesílání se vyskytla chyba. Doporučujeme kontaktovat nás telefonicky, případně na email adiacharvat@email.cz"
        );
      });
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          {dialog && <KontaktDialog open={dialog} setOpen={setDialog} />}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box sx={{ mt: "50px" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                height: "fit-content",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  maxWidth: "1200px",
                  width: "100%",
                  display: "flex",
                  mb: "-3px",
                }}
              >
                {getVlnky(3)}
              </Box>
            </Box>
            <Box sx={{ mt: "0px", backgroundColor: greenNormal, pb: "80px" }}>
              <Box
                ref={kontaktRef}
                sx={{ width: "100%", textAlign: "center", pt: "50px" }}
              >
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  <Twig />
                </Box>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  <TwigMobile />
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: "36px", sm: "45px" },
                    fontFamily: "EB Garamond",
                    color: "white",
                    mb: "20px",
                    letterSpacing: "0.00938em",
                    lineHeight: "1.5",
                  }}
                  variant="h1"
                >
                  Kontaktujte mě
                </Typography>
              </Box>
              <Container component="main" maxWidth="xs" sx={{}}>
                <Grid container spacing={2} columns={24} sx={{ mt: "80px" }}>
                  <Grid item xs={24} sx={{}}>
                    <StyledTextField
                      name="name"
                      type="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      label="Jméno a příjmení"
                      helperText={
                        (errors.name && touched.name) ||
                        (errors.name && submited)
                          ? errors.name
                          : null
                      }
                      error={Boolean(
                        (errors.name && touched.name) ||
                          (errors.name && submited)
                      )}
                    />
                  </Grid>
                  <Grid item xs={24} sm={12} sx={{}}>
                    <StyledTextField
                      name="email"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      label="Email"
                      helperText={
                        (errors.email && touched.email) ||
                        (errors.email && submited)
                          ? errors.email
                          : null
                      }
                      error={Boolean(
                        (errors.email && touched.email) ||
                          (errors.email && submited)
                      )}
                    />
                  </Grid>
                  <Grid item xs={24} sm={12} sx={{}}>
                    <StyledTextField
                      name="phone"
                      type="phone"
                      onChange={(e) => {
                        let value = e.target.value;
                        e.target.value = value.replace(/[^0-9+]/g, "");
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.phone}
                      label="Telefon"
                      helperText={
                        (errors.phone && touched.phone) ||
                        (errors.phone && submited)
                          ? errors.phone
                          : null
                      }
                      error={Boolean(
                        (errors.phone && touched.phone) ||
                          (errors.phone && submited)
                      )}
                    />
                  </Grid>
                  <Grid item xs={24} sx={{}}>
                    <StyledTextField
                      name="message"
                      type="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      multiline
                      label="Vaše zpráva"
                      helperText={
                        (errors.message && touched.message) ||
                        (errors.message && submited)
                          ? errors.message
                          : null
                      }
                      error={Boolean(
                        (errors.message && touched.message) ||
                          (errors.message && submited)
                      )}
                    />
                  </Grid>
                </Grid>
              </Container>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  mt: "80px",
                }}
              >
                <Box
                  component={Button}
                  type="submit"
                  sx={{
                    textTransform: "none",
                    "&:hover": { background: "none" },
                  }}
                  disableRipple
                >
                  <Box
                    component={motion.div}
                    whileHover={{ scale: 1.15 }}
                    transition={{
                      type: "spring",
                      stiffness: 600,
                      damping: 100,
                    }}
                    sx={{
                      "&:hover": { cursor: "pointer" },
                      width: "fit-content",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      setSubmited(true);
                    }}
                  >
                    <Typography
                      sx={{
                        width: "fit-content",
                        fontSize: "24px",
                        fontFamily: "EB Garamond",
                        color: "white",
                        mb: "10px",
                        userSelect: "none",
                      }}
                    >
                      Odeslat
                    </Typography>
                  </Box>
                </Box>
                {mainError && (
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontFamily: "EB Garamond",
                        color: "red",
                      }}
                    >
                      {mainError}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}
