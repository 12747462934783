export default {
  palette: {
    primary: {
      light: `#008394`,
      main: `#008394`,
      dark: "#008394",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    useNextVariants: true,
  },
};
