import { Box, Container } from "@mui/material";
import React, { Component } from "react";
import IntroPanel from "./Components/IntroPanel";
import Navbar from "./Components/Navbar";
import SvatebniNabidka from "./Components/SvatebniNabidka";
import YoutubePanel from "./Components/YoutubePanel";
import Zajistuji from "./Components/Zajistuji";
import Omne from "./Components/Omne";
import Reference from "./Components/Reference";
import KontaktniFormular from "./Components/KontaktniFormular";
import OutroPanel from "./Components/OutroPanel";
import axios from "axios";
import { sendContact } from "../Firebase/config";
import { getReferences } from "../Firebase/references";
import { getSessionRef, setSessionRef } from "./SessionStorage";
import Fab from "@mui/material/Fab";
import getListSiti from "../Images/Icons/SocialniSite/SocSite";
import { motion } from "framer-motion";

axios.defaults.baseURL =
  "https://europe-west1-adelacharvatova-115c6.cloudfunctions.net/api";

export default class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      references: null,
      failed: false,
    };

    this.zajistujiRef = React.createRef();
    this.omneRef = React.createRef();
    this.kontaktRef = React.createRef();
    this.sNabidkaRef = React.createRef();

    this.kontaktRefScroll = this.kontaktRefScroll.bind(this);
  }

  componentDidMount() {
    /*sendContact({ text: "messageText" })
      .then((result) => {
        const data = result.data;
        console.log(data);
      })
      .catch((error) => {
        console.log("ERROR ", error);
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log("ERROR ", code, message, details);
      });*/

    if (getSessionRef(window) === undefined) {
      getReferences()
        .then((references) => {
          setSessionRef(references, window);
          this.setState({ references });
        })
        .catch((error) => {
          if (error.code == "permission-denied") {
            setSessionRef(false, window);
          }
        });
    } else {
      if (getSessionRef(window) !== false || getSessionRef(window) !== null) {
        this.setState({ references: getSessionRef(window) });
      }
    }
  }

  zajistujiRefScroll = () => {
    window.scrollTo({
      left: 0,
      top: this.zajistujiRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  omneRefScroll = () => {
    window.scrollTo({
      left: 0,
      top: this.omneRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  kontaktRefScroll = () => {
    window.scrollTo({
      left: 0,
      top: this.kontaktRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  sNabidkaRefScroll = () => {
    window.scrollTo({
      left: 0,
      top: this.sNabidkaRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minWidth: "250px",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            ml: "10px",
            mb: "10px",
            zIndex: 1000,
          }}
        >
          {getListSiti().map((sit, index) => (
            <Fab sx={{ mb: "10px" }}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.1 }}
                transition={{
                  type: "spring",
                  stiffness: 600,
                  damping: 70,
                }}
                key={"MotionMainIconList" + index}
                sx={{ height: "100%" }}
              >
                <Box
                  component="a"
                  href={sit.link}
                  target="_blank"
                  key={"SitList" + index}
                  sx={{ width: "100%", height: "100%" }}
                >
                  {sit.icon}
                </Box>
              </Box>
            </Fab>
          ))}
        </Box>

        <Box
          sx={{
            maxWidth: "1200px",
          }}
        >
          <Navbar
            zajistujiRef={this.zajistujiRefScroll}
            omneRef={this.omneRefScroll}
            kontaktRef={this.kontaktRefScroll}
          />
          <IntroPanel />
        </Box>
        <Container disableGutters component="main" maxWidth="lg" sx={{}}>
          <Box>
            <Zajistuji
              zajistujiRef={this.zajistujiRef}
              kontaktRefScroll={this.kontaktRefScroll}
              sNabidkaRefScroll={this.sNabidkaRefScroll}
            />
            <Omne
              omneRef={this.omneRef}
              kontaktRefScroll={this.kontaktRefScroll}
            />
            <YoutubePanel />
            <SvatebniNabidka sNabidkaRef={this.sNabidkaRef} />
            {this.state.references && (
              <Reference references={this.state.references} />
            )}
            <KontaktniFormular kontaktRef={this.kontaktRef} />
          </Box>
        </Container>
        <Box
          sx={{
            maxWidth: "1200px",
          }}
        >
          <OutroPanel />
        </Box>
        {/*<Box
          sx={{
            maxWidth: "1200px",
          }}
        ></Box>
        <Box>
          
        </Box>*/}
      </Box>
    );
  }
}
