import { Box, Typography } from "@mui/material";
import React from "react";
import { greenNormal } from "../../Colors";
import F3 from "../../Images/Photos/F3.webp";

const textStyle = {
  color: "white",
  fontFamily: "RawlineRegular",
  fontSize: "15px",
  textDecoration: "none",
};

export default function OutroPanel() {
  return (
    <Box sx={{ backgroundColor: greenNormal, pb: "8px" }}>
      <Box sx={{ position: "relative", textAlign: "center" }}>
        <img src={F3} alt="Adia hrající na ukulele" style={{ width: "100%" }} />
        <footer>
          <Box
            sx={{
              backgroundColor: greenNormal,
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography sx={textStyle}>
              © 2022 Adia | Adéla Charvátová |{" "}
              <Box
                component="a"
                href="mailto:adiacharvat@gmail.com"
                sx={textStyle}
              >
                adiacharvat@gmail.com
              </Box>{" "}
              |{" "}
              <Box component="a" href="tel:+420797996873" sx={textStyle}>
                tel: 797996873
              </Box>{" "}
              |{" "}
              <Box
                component="a"
                href="https://goo.gl/maps/rmrvAEXi5YCqM5SV7"
                target="_blank"
                sx={textStyle}
              >
                Brno, Högrova 2862/2
              </Box>{" "}
            </Typography>
          </Box>
        </footer>
      </Box>
    </Box>
  );
}
