// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBevE_QYraXZsnxY1fNavWeLZNYeSbJvtI",
  authDomain: "adelacharvatova-115c6.firebaseapp.com",
  projectId: "adelacharvatova-115c6",
  storageBucket: "adelacharvatova-115c6.appspot.com",
  messagingSenderId: "488556766751",
  appId: "1:488556766751:web:313ef884821b7f97f7172a",
  measurementId: "G-32REV7RCE8",
};

const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6Ldl_RUjAAAAAHcTPJCAWh875tO30hWUTIkyXDp7"),
  isTokenAutoRefreshEnabled: true,
});

const analytics = getAnalytics(app);
const functions = getFunctions(app, "europe-west1");

export const db = getFirestore(app);
export const sendContact = httpsCallable(functions, "sendContact");
