import React from "react";
import { greenLight, greenNormal } from "../../Colors";
import { ReactComponent as V1 } from "./V1.svg";
import { ReactComponent as V2 } from "./V2.svg";
import { ReactComponent as V3 } from "./V3.svg";
import { ReactComponent as V4 } from "./V4.svg";

//width="2435pt" height="123.2pt"
const Vlnka1 = () => {
  const editedIcon = React.cloneElement(<V1 />, {
    style: {
      width: "100%",
      aspectRatio: 2435 / 123.2,
      maxWidth: "1200px",
      fill: greenNormal,
    },
  });
  return <>{editedIcon}</>;
};

const Vlnka2 = () => {
  const editedIcon = React.cloneElement(<V2 />, {
    style: {
      width: "100%",
      maxWidth: "1200px",
      aspectRatio: 2440 / 151,
      fill: greenNormal,
    },
  });
  return <>{editedIcon}</>;
};

const Vlnka3 = () => {
  const editedIcon = React.cloneElement(<V3 />, {
    style: {
      width: "100%",
      aspectRatio: 2440 / 212,
      maxWidth: "1200px",
      fill: greenLight,
    },
  });
  return <>{editedIcon}</>;
};

const Vlnka4 = () => {
  const editedIcon = React.cloneElement(<V4 />, {
    style: {
      maxHeight: "92px",
      width: "100%",
      aspectRatio: 2440 / 170,
      maxWidth: "1200px",
      fill: greenNormal,
    },
  });
  return <>{editedIcon}</>;
};

const Vlnky = [<Vlnka1 />, <Vlnka2 />, <Vlnka3 />, <Vlnka4 />];
function getVlnky(index) {
  return Vlnky[index];
}

export default getVlnky;
